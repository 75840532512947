<template>
  <div class="based-content">
    <div class="based-title">
        <span>基础信息</span>
        <div class="based-line"></div>
    </div>
    <div class="based-main">
        <div class="based-form">
            <el-form label-position="right" :rules="rules" ref="basedFormData" :model="basedFormData" style="display: flex;flex-direction: column;justify-content: space-between;">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="方案名称" prop="programmeName">
                            <el-select v-model="basedFormData.programmeName" filterable  placeholder="请选择" @change="scheduleChange">
                                <el-option
                                    v-for="item in scheduleList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="basedFormData.programmeName!=''">
                        <el-form-item label="巡课方式">
                            <el-radio v-model="basedFormData.programmeType" label="1" v-show="basedFormData.programmeType == '1'">自由巡课</el-radio>
                            <el-radio v-model="basedFormData.programmeType" label="2" v-show="basedFormData.programmeType == '2'">固定课程巡课</el-radio>
                        </el-form-item>
                    </el-col>
                    <!-- 自由巡课 targetType 巡课对象类型1班级2教师 -->
                    <el-col :span="8" v-if="basedFormData.programmeName!='' && basedFormData.programmeType == '1'">
                        <el-form-item label="巡课对象" v-if="targetType == '1'" prop="targetName">
                            <cascader-item
                                class="expand-filter-cascader"
                                :data="gradeObj"
                                @changeCascader="changeCascader"
                            />
                        </el-form-item>
                        <el-form-item label="巡课对象" v-else-if="targetType == '2'" prop="targetName">
                            <el-select v-model="basedFormData.classTeacherId" filterable placeholder="请选择" @change="teacherSelect">
                                <el-option
                                    v-for="item in teacherOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- 固定巡课 -->
                    <el-col :span="8" v-if="basedFormData.programmeName!='' && basedFormData.programmeType == '2'">
                        <el-form-item label="巡课对象" prop="targetName">
                            <!-- 点击出现弹框 -->
                            <el-tooltip class="item" effect="dark" :content="basedFormData.targetName" :disabled="basedFormData.targetName < 19" placement="top-start">
                                <el-input v-model="basedFormData.targetName" @focus="fixedDialog()"></el-input>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- 自由巡课 -->
                <el-row :gutter="20" v-if="basedFormData.programmeName!='' && basedFormData.programmeType == '1'">
                    <el-col :span="8">
                        <el-form-item label="上课教师" v-if="targetType=='1'" prop="classTeacherId">
                            <select-tree
                                ref="selectTree"
                                placeholder="选择教师"
                                v-model="basedFormData.classTeacherId"
                                :data="teacherList"
                                :props="{
                                    value: 'id',
                                    label: (data) => data.name,
                                    children: 'children'
                                }"
                                @handlerSupervisingTeacherChange="handlerTeacherChange"
                            >
                            </select-tree>
                        </el-form-item>

                        <el-form-item label="上课班级" v-else-if="targetType=='2'" prop="classId">
                            <cascader-item
                                class="expand-filter-cascader"
                                :data="gradeObj"
                                @changeCascader="selectGrade"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="科目" prop="subjectName">
                            <el-select v-model="basedFormData.subjectName" filterable  placeholder="请选择" @change="subjectChange">
                                <el-option
                                    v-for="item in subjectList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <span style="display: flex;">
                            <span class="time-title">上课时间</span>
                            <el-form-item prop="value1">
                                <el-date-picker
                                    v-model="basedFormData.value1"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="{disabledDate}"
                                    placeholder="选择日期"
                                    style="width: 125px;">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item prop="value2">
                                <el-time-picker
                                    is-range
                                    v-model="basedFormData.value2"
                                    format="HH:mm"
                                    value-format="HH:mm"
                                    range-separator="-"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    placeholder="选择时间范围"
                                    style="width: 125px;margin-left:10px">
                                </el-time-picker>
                            </el-form-item>
                        </span>
                    </el-col>
                </el-row>
                <!-- 固定巡课 -->
                <el-row :gutter="20" v-if="basedFormData.programmeName!='' && basedFormData.programmeType == '2'" style="display: flex;justify-content: space-between;">
                    <el-col :span="6">
                        <el-form-item label="巡课班级" prop="className">
                            <el-input :disabled="true" v-model="basedFormData.className" clearable style="width:150px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="上课教师" prop="classTeacherName">
                            <el-tooltip class="item" effect="dark" :content="basedFormData.classTeacherName" :disabled="basedFormData.classTeacherName < 8" placement="top-start">
                                <el-input :disabled="true" v-model="basedFormData.classTeacherName" style="width:120px"></el-input>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="科目" prop="subjectName">
                            <el-input :disabled="true" v-model="basedFormData.subjectName" style="width:120px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="上课时间" prop="classTime">
                            <el-input :disabled="true" v-model="basedFormData.classTime" style="width:260px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>

    <div class="based-title">
        <span>巡课详情</span>
        <div class="based-line"></div>
    </div>
    <div class="detail-main">
        <div class="behavior-div" v-if="basedFormData.programmeName!='' && infoBool">
            <!-- 教师行为 -->
            <div v-if="contentList.hasOwnProperty('1')">
                <div class="behavior-title">
                    <span class="title-line"></span>
                    <span class="title-info">教师行为</span>
                </div>
                <div v-for="(item,index) in keyArrTeacher" :key="index" class="behavior-content">
                    <div class="behavior-content-title">{{index+1}} . {{contentList[1][item][0].contentName}}</div>
                    <el-form :model="infoTeacher[item]" :rules="rulesTeacher[item]">
                        <div style="display: flex;flex-direction: column;flex-wrap" v-if="contentList[1][item][0].type == '1'">
                            <div class="student-radio-div">
                                <el-radio-group v-model="infoTeacher[item].contentSetId" v-for="(subItem,idx) in contentList[1][item]" :key="idx" @input="changeRadio(subItem,'teacher')" class="radioDiv">
                                    <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.setName,subItem.setScore)" :disabled="(subItem.setName&&subItem.setName.length>26) ? false : true">
                                        <el-radio :label="subItem.setId" class="display:flex;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                                            <span>{{ subItem.setName }} </span>
                                            <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                        </el-radio>
                                    </el-tooltip>
                                </el-radio-group>
                            </div>
                            <div class="propt" v-if="showSubmit && !infoTeacher[item].contentSetId && CorData[item]['rules']['contentSetId']['type']">请选择该选项</div>
                        </div>
                        <div style="display: flex;flex-direction: column;" v-else-if="contentList[1][item][0].type == '2'">
                            <div class="student-radio-div">
                                <el-checkbox-group v-model="infoTeacher[item].contentSetIdBack" v-for="(subItem,idx) in contentList[1][item]" :key="idx" class="radioDiv" @change="changeCheckBox(subItem,'teacher')">
                                    <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.setName,subItem.setScore)" :disabled="(subItem.setName&&subItem.setName.length>26) ? false : true">
                                        <el-checkbox :label="subItem.setId" class="display:flex;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                                            <span>{{ subItem.setName }}</span>
                                            <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                        </el-checkbox>
                                    </el-tooltip>
                                </el-checkbox-group>
                            </div>
                            <div class="propt" v-if="showSubmit && !infoTeacher[item].contentSetIdBack.length && CorData[item]['rules']['contentSetIdBack']['type']">请勾选选项</div>
                        </div>
                        <div  v-else-if="contentList[1][item][0].type == '3'" class="remark">
                            <div style="margin-left:23px">
                                <span v-show="contentList[1][item][0].lowScore || contentList[1][item][0].lowScore == 0">
                                    <el-input-number v-model="infoTeacher[item].contentSetScore" :precision="0" controls-position="right" :min="contentList[1][item][0].lowScore" :max="contentList[1][item][0].highScore"></el-input-number>
                                    <span class="remark-info">({{ contentList[1][item][0].lowScore }} - {{ contentList[1][item][0].highScore }} 分)</span>
                                    <span style="font-size:12px;color:red;display:flex;justify-content:flex-start" v-if="showSubmit &&(infoTeacher[item].contentSetScore!=0 && !infoTeacher[item].contentSetScore) && CorData[item]['rules']['contentSetScore']['type']">请填写分数</span>
                                </span>
                            </div>
                            <div style="display: flex;flex-direction: column;margin-left:23px">
                                <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    maxlength="100"
                                    show-word-limit
                                    style="width: 920px;margin-top: 10px;"
                                    v-model="infoTeacher[item].contentSetInfo">
                                </el-input>
                                <div style="font-size:12px;color:red;display:flex;justify-content:flex-start" v-if="showSubmit && !infoTeacher[item].contentSetInfo && CorData[item]['rules']['contentSetInfo']['type']">请填写评价</div>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <!-- 学生行为 -->
            <div v-if="contentList.hasOwnProperty('2')" class="behavior-line"></div>
            <div v-if="contentList.hasOwnProperty('2')">
                <div class="behavior-title">
                    <span class="title-line"></span>
                    <span class="title-info">学生行为</span>
                </div>
                <div v-for="(item,index) in keyArrStu" :key="index" class="behavior-content">
                    <div class="behavior-content-title">{{index+1}} . {{contentList[2][item][0].contentName}}</div>
                    <el-form>
                        <div  v-if="contentList[2][item][0].type == '1'">
                            <div class="student-radio-div">
                                <el-radio-group v-model="infoStudent[item].contentSetId" v-for="(subItem,idx) in contentList[2][item]" :key="idx" @input="changeRadio(subItem,'student')" class="radioDiv">
                                    <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.setName,subItem.setScore)" :disabled="(subItem.setName&&subItem.setName.length>26) ? false : true">
                                        <el-radio :label="subItem.setId" class="display:flex;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                                            <span>{{ subItem.setName }}</span>
                                            <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                        </el-radio>
                                    </el-tooltip>
                                </el-radio-group>
                            </div>
                            <div class="propt" v-if="showSubmit && !infoStudent[item].contentSetId && CorDataStu[item]['rules']['contentSetId']['type']">请选择该选项</div>
                        </div>
                        <div v-else-if="contentList[2][item][0].type == '2'">
                            <div class="student-radio-div">
                                <el-checkbox-group v-model="infoStudent[item].contentSetIdBack" v-for="(subItem,idx) in contentList[2][item]" :key="idx" class="radioDiv" @change="changeCheckBox(subItem,'student')">
                                    <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.setName,subItem.setScore)" :disabled="(subItem.setName&&subItem.setName.length>26) ? false : true">
                                        <el-checkbox :label="subItem.setId" class="display:flex;align-items:center;margin-right:38px">
                                            <span>{{ subItem.setName }}</span>
                                            <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                        </el-checkbox>
                                    </el-tooltip>
                                </el-checkbox-group>
                            </div>
                            <div class="propt" v-if="showSubmit && !infoStudent[item].contentSetIdBack.length && CorDataStu[item]['rules']['contentSetIdBack']['type']">请勾选选项</div>
                        </div>
                        <div v-else-if="contentList[2][item][0].type == '3'" class="remark">
                            <div style="margin-left:23px">
                                <span v-show="contentList[2][item][0].lowScore || contentList[2][item][0].lowScore==0">
                                    <el-input-number v-model="infoStudent[item].contentSetScore" :precision="0" controls-position="right" :min="contentList[2][item][0].lowScore" :max="contentList[2][item][0].highScore"></el-input-number>
                                    <span class="remark-info">({{ contentList[2][item][0].lowScore }} - {{ contentList[2][item][0].highScore }} 分)</span>
                                    <span style="font-size:12px;color:red;display:flex;justify-content:flex-start" v-if="showSubmit && (infoStudent[item].contentSetScore!=0 && !infoStudent[item].contentSetScore) && CorDataStu[item]['rules']['contentSetScore']['type']">请填写分数</span>
                                </span>
                            </div>
                            <div style="display: flex;flex-direction: column;margin-left:23px">
                                <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    maxlength="100"
                                    show-word-limit
                                    style="width: 920px;margin-top: 10px;"
                                    v-model="infoStudent[item].contentSetInfo">
                                </el-input>
                                <div style="font-size:12px;color:red;display:flex;justify-content:flex-start" v-if="showSubmit && !infoStudent[item].contentSetInfo && CorDataStu[item]['rules']['contentSetInfo']['type']">请填写评价</div>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <!-- 备注说明 -->
            <div v-if="assessType.includes('3')" class="behavior-line"></div>
            <div v-if="assessType.includes('3')">
                <div class="behavior-title">
                    <span class="title-line"></span>
                    <span class="title-info">备注说明</span>
                </div>
                <div class="behavior-content">
                    <!-- <div>{{contentList[3][item][0].contentName}}</div> -->
                    <div style="margin-left:23px">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            maxlength="500"
                            show-word-limit
                            style="width: 920px;margin-top: 10px;"
                            v-model="infoRemark.contentSetInfo">
                        </el-input>
                    </div>

                </div>
            </div>
        </div>
        <div v-else>
            <div class="empty-div">
                <img :src="emptyImg" alt="">
                <div class="empty-info">请先选择方案名称~</div>
            </div>
        </div>
    </div>
    <!-- 固定巡课选择巡课对象 -->
    <dialog-wrapper :dialogObj="patrolTargetDialogObj" @handleClose="handlepatrolTargetDialogClose">
        <div>
            <!-- 查询 -->
            <div class="patrol-target-search">
                <div style="display:flex;margin-bottom:13px">
                    <cascader-item
                        style="height:32px;margin-right:10px"
                        class="expand-filter-cascader"
                        :data="gradeObj"
                        @changeCascader="changeClassDialog"
                    />

                    <el-input style="width:160px;height:32px;margin-right:10px" v-model="targetObj.teacher" placeholder="教师"></el-input>
                    <el-button type="primary" @click="getTargetObj">查询</el-button>
                </div>
                <div class="patrol-target-line"></div>
            </div>
            <!-- 课程表 -->
            <div class="patrol-target-timetable" v-if="list.length>0">
                <div v-for="(item,index) in list" :key="index">
                    <time-table :data="item" @selectTarget = "selectTarget"></time-table>
                </div>
            </div>
            <div v-else class="target-img">
                <img :src="emptyImg" alt="">
                <div class="target-info">暂无数据</div>
            </div>
        </div>
        <div class="btns" slot="footer">
            <el-button @click="handlepatrolTargetDialogClose">取消</el-button>
            <el-button type="primary" @click="targetDialogClick">确定</el-button>
        </div>
    </dialog-wrapper>

    <form-submit-btn>
        <div slot="formSubmitBtn">
            <el-button type="primary" :disabled="submitDisabled" @click="submit()">提交</el-button>
        </div>
    </form-submit-btn>
  </div>
</template>

<script>
import FormSubmitBtn from "../../Sub/FormSubmitBtn/index.vue";
import { classScheduleModel } from '@/models/ClassSchedule.js';
import { TeachingResearchCourseScheduleTempModel } from '@/models/TeachingResearchCourseScheduleTemp.js'
import { mapState } from 'vuex';
import { DialogWrapper, debounce } from "common-local";
import TimeTable from './TimeTable.vue';
import SelectTree from '../../TeachingResearchCourseScheduleTemp/Sub/SelectTree'
import CascaderItem from "../../Sub/FormData/Cascader.vue";
import { formatTreeData } from "@/libs/utils";
export default {
    components:{
        DialogWrapper,
        TimeTable,
        SelectTree,
        FormSubmitBtn,
        CascaderItem
    },
    data(){
        return{
            submitDisabled:false,
            value1:'',
            value2:'',
            basedFormData:{
                value1:'',
                value2:'',
                schoolId:'',
                programmeId:'',//方案id
                programmeName:'',//方案名称
                programmeType:'',//方案类型
                targetId:'',//巡课对象id
                targetName:'',//巡课对象名称
                classId:'',//巡课班级id
                className:'',//巡课班级名称
                classTeacherId:'',//上课教师id
                classTeacherName:'',//上课教师名称
                classTime:'',//上课时间
                subjectId:'',//巡课科目id
                subjectName:'',//巡课科目名称
                gradeId:'',//年级id
                gradeName:'',//年级名称
                infoList:[]
            },
            scheduleList:[],//巡课方案列表
            subjectList:[],//科目列表
            startTime:'',//上课时间起始时间
            endTime:'',//上课时间截止时间
            patrolTargetDialogObj:{
                title: '选择巡课对象',
                dialogVisible: false,
                width: '600px',
            },
            list:[],
            targetOptions:[],//固定巡课巡课对象弹框班级下拉框
            targetObj:{
                class:'',
                teacher:''
            },
            currentTarget:null,//被选中的巡课对象
            targetType:null,//自由巡课巡课对象1班级2教师
            contentList:[], //详情列表
            keyArrTeacher:[],//教师问题Id数组
            infoTeacher: {},//保存的内容列表
            infoStu:{},
            teacherList:[],//上课教师列表

            keyArrStu:[],//学生问题Id数组
            infoStudent: {},//保存的内容列表
            infoRemark:{},//保存时备注说明

            gradeList:[],
            gradeObj:{   //自由巡课对象班级下拉列表
                key: 'classIds',
                value: [],
                organType: "",
                list: [],
                cascaderProps: {
                    label: "name",
                    multiple: false,
                    value: "id",
                    emitPath: false
                },
                placeholder:'巡课班级',
                filter(data) {
                    return data.organType !== '1';
                },
            },
            rules:{
                programmeName:[
                    { required: true, message: '请输入方案名称', trigger: 'blur' },
                ],
                targetName:[
                    { required: true, message: '请选择巡课对象', trigger: 'change' }
                ],
                classTeacherId:[
                    { required: true, message: '请选择上课教师', trigger: 'blur' }
                ],
                classId:[
                    { required: true, message: '请选择巡课班级', trigger: 'blur' }
                ],
                subjectName:[
                    { required: true, message: '请选择巡课科目', trigger: 'blur' }
                ],
                classTime:[
                    { required: true, message: '请选择上课日期', trigger: 'change' }
                ],
                value1:[
                    { required: true, message: '请选择上课日期', trigger: 'change' }
                ],
                value2:[
                    { required: true, message: '请选择上课时间', trigger: 'change' }
                ]
            },
            rulesTeacher: {},
            rulesStudent:{},
            CorData: {},
            CorDataStu:{},
            showSubmit: false,
            teacherOptions:[],//自由巡课对象为教师-教师列表
            firstProName:'',//保存方案修改前的名称和id
            firstProId:'',
            assessType:'',//教师/学生/备注说明-1，2，3
            currentProFixedObj:[],//当前方案固定巡课对象的数据
            currentTargetTemporary:null,//固定巡课弹框当前被点击的数据
            infoBool:false,//交互数据是否处理完
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        }),
        emptyImg(){
            return require('@/assets/images/empty-patrol.png')
        },
        classes() {
            return (e) => {
                console.log(e,'eeee')
                if (e) {
                    return {
                        'width':"100%",
                        'border': '1px solid red'
                    }
                }
            }
        }
    },
    created(){
        this.getSubjectList()
        this.getTeacherList()
        this.getProgrammeList()
    },
    methods:{
        // 单选多选鼠标悬浮提示框有分值处理
        handleTooltip(name,score){
            if(score || score == 0){
                let a = ''
                if(score>0){
                    a = '+' + score + '分'
                }else{
                    a = score + '分'
                }
                return name + '(' + a + ')'
            }else{
                return name
            }
        },
        /**
         * @Description: 自由巡课对象教师，教师下拉框改变
         * @Author: mrz
         * @Date: 2024-09-02 19:02:17
         */
         teacherSelect(val){
            const a = this.teacherOptions.find(item => item.value == val)
            this.basedFormData.targetId = val
            this.basedFormData.targetName = a.label
            this.basedFormData.classTeacherName = a.label
         },
        /**
         * @Description: 教师巡课详情单选改变
         * @Author: mrz
         * @Date: 2024-08-27 14:55:13
         * @param {*} val
         */
        changeRadio(val,type){
            if(type == 'teacher'){
                this.infoTeacher[val.contentId].contentSetScore = val.setScore
            }else if(type == 'student'){
                this.infoStudent[val.contentId].contentSetScore = val.setScore
                console.log(this.infoStudent)
            }
        },
        /**
         * @Description: 巡课详情多选框改变
         * @Author: mrz
         * @Date: 2024-08-29 14:21:53
         */
         changeCheckBox(val,type){
            if(type == 'teacher'){
                this.infoTeacher[val.contentId].contentSetScore = val.setScore
            }else if(type == 'student'){
                this.infoStudent[val.contentId].contentSetScore = val.setScore
            }
         } ,
        /**
         * @Description: 固定巡课弹框班级下拉框
         * @Author: mrz
         * @Date: 2024-09-02 19:37:32
         */
         changeClassDialog(data){
            console.log('固定巡课弹框班级下拉框选定：',data)
            this.targetObj.class = data.data.value
         },
        /**
         * @Description: 自由巡课巡课对象为班级，选择班级
         * @Author: mrz
         * @Date: 2024-08-27 10:22:56
         */
         changeCascader(data) {
            console.log('this.gradeList',this.gradeList)
            this.basedFormData.targetId = data.data.value
            const a = this.gradeList.find(item => item.id == data.data.value)
            this.basedFormData.classId = a.id
            this.basedFormData.className = a.name
            const b = this.gradeList.find(item => item.id == a.parentOrg)
            if(this.targetType=='1'){
                this.basedFormData.targetName = b.name + '/' + a.name
            }
            this.basedFormData.gradeId = b.id
            this.basedFormData.gradeName = b.name
            let node;
            if (data.ref.getCheckedNodes()[0]) {
                node = data.ref.getCheckedNodes()[0].data;
                console.log(node, 'node');
            } else {
            }
            data.ref.dropDownVisible = false;
        },
        /**
         * @Description: 自由巡课巡课对象为教师,班级的下拉列表
         * @Author: mrz
         * @Date: 2024-09-03 20:02:30
         * @param {*} type
         */
         selectGrade(data){
            this.basedFormData.classId = data.data.value
            let node = data.ref.getCheckedNodes()[0].data
            this.basedFormData.className = node.name
            this.basedFormData.gradeId = node.parentOrg
            const b = data.data.list.find(item => item.id == node.parentOrg)
            this.basedFormData.gradeName = b.name
         },
        /**
         * @Description:提交保存巡课记录
         * @Author: mrz
         * @Date: 2024-08-26 19:52:11
         */
        async submit(){
            this.showSubmit = true;
            if(this.basedFormData.programmeType == '1'){
                if(this.targetType == '1'){
                    this.basedFormData.classTeacherName = this.$refs.selectTree.valueLabel ? this.$refs.selectTree.valueLabel : ''
                }
                this.basedFormData.classTime = this.basedFormData.value1 + ' '+(this.basedFormData.value2 ? this.basedFormData.value2[0]+ '-' + this.basedFormData.value2[1] : '')
            }

            this.basedFormData.infoList = []
            await this.$refs.basedFormData.validate()
            console.log(this.basedFormData,'基础信息表单校验通过')
            // 校验通过
            // 处理教师行为答案数组
            let teacherCheck = []
            this.keyArrTeacher.forEach(async item => {
                console.log('教师行为校验--',this.infoTeacher[item])
                if(this.contentList[1][item][0]['type'] == '1' && this.infoTeacher[item].contentSetId == ''){
                    teacherCheck.push('false')
                }else if(this.contentList[1][item][0]['type'] == '2' && this.infoTeacher[item].contentSetIdBack.length == 0){
                    teacherCheck.push('false')
                }else if(this.contentList[1][item][0]['type'] == '3'){
                    if(this.infoTeacher[item].contentSetInfo == '' || (this.contentList[1][item][0].lowScore != null && this.infoTeacher[item].contentSetScore == undefined)){
                        teacherCheck.push('false')
                        // console.log(this.contentList[1][item][0].lowScore)
                        // console.log(this.infoTeacher[item].contentSetScore)
                        // console.log('type==3',teacherCheck,this.infoTeacher[item],this.contentList[1][item])
                    }
                }
                if(Array.isArray(this.infoTeacher[item].contentSetIdBack)){
                    this.infoTeacher[item].contentSetScore = [] //多选分数数组
                    this.infoTeacher[item].contentSetId = this.infoTeacher[item].contentSetIdBack.toString()
                    this.contentList[1][item].forEach(subVal => {
                        if(this.infoTeacher[item].contentSetId.includes(subVal.setId)){
                            this.infoTeacher[item].contentSetScore.push(subVal.setScore)
                        }
                    })
                    this.infoTeacher[item].contentSetScore = this.infoTeacher[item].contentSetScore.toString()
                }
                this.basedFormData.infoList.push(this.infoTeacher[item])
            })
            let teacherCheckRes = teacherCheck.some(t => t=='false')
            console.log('teacherCheck',teacherCheck)
            if(teacherCheckRes){
                return
            }

            console.log('教师行为表单校验通过')
            // 处理学生行为答案数组
            let stuCheck = []
            this.keyArrStu.forEach(item => {
                 console.log('学生行为校验--',this.infoStudent[item])
                if(this.contentList[2][item][0]['type'] == '1' && this.infoStudent[item].contentSetId == ''){
                    stuCheck.push('false')
                }else if(this.contentList[2][item][0]['type'] == '2' && this.infoStudent[item].contentSetIdBack.length == 0){
                    stuCheck.push('false')
                }else if(this.contentList[2][item][0]['type'] == '3'){
                    if(this.infoStudent[item].contentSetInfo == '' || (this.contentList[2][item][0].lowScore != null && !this.infoStudent[item].contentSetScore == undefined)){
                        stuCheck.push('false')
                        console.log('type==3-stuCheck',stuCheck,this.infoStudent[item],this.contentList[2][item])
                    }
                }
                if(Array.isArray(this.infoStudent[item].contentSetIdBack)){
                    this.infoStudent[item].contentSetScore = [] //多选分数数组
                    this.infoStudent[item].contentSetId = this.infoStudent[item].contentSetIdBack.toString()
                    this.contentList[2][item].forEach(subVal => {
                        if(this.infoStudent[item].contentSetId.includes(subVal.setId)){
                            this.infoStudent[item].contentSetScore.push(subVal.setScore)
                        }
                    })
                    this.infoStudent[item].contentSetScore = this.infoStudent[item].contentSetScore.toString()
                }
                this.basedFormData.infoList.push(this.infoStudent[item])
            })
            let stuCheckRes = stuCheck.some(s => s == 'false')
            if(stuCheckRes){
                return
            }
            console.log("学生行为表单校验通过")
            this.basedFormData.schoolId = this.schoolId
            if(this.assessType.includes('3')){
                this.basedFormData.infoList.push(this.infoRemark)
            }
            console.log('保存时的参数：',this.basedFormData)

            const classscheduleModel = new classScheduleModel();
            classscheduleModel.savePatrolRecordList(this.basedFormData).then(res => {
                console.log('保存结果',res)
                if(res.data.code == 200){
                    this.$message.success("保存成功")
                    this.submitDisabled = true
                    this.$emit('backList')
                }else{
                    this.$message.error(res.data.msg)
                    this.submitDisabled = false
                }
            })
        },
        /**
         * @Description: 获取教师列表
         * @Author: mrz
         * @Date: 2024-08-26 18:59:16
         */
        getTeacherList(){
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getSchoolTeacherList({'schoolId': this.schoolId, "applyPerson": "1"}).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'res.data.data 教师列表')
                    this.setDisabledOption(res.data.data);
                    this.teacherList = res.data.data;
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-28 10:09:29
         */
         setDisabledOption (list) {
            list.forEach((item) => {
                if (item.key !== 'teacher') {
                    this.$set(item, 'disabled', true)
                } else {
                    this.$set(item, 'disabled', false)
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption (item.children)
                }
            })
         },
        /**
         * @Description: 获取巡课方案下拉框数据
         * @Author: mrz
         * @Date: 2024-08-24 09:31:16
         */
        getProgrammeList(){
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getProgrammeList({}).then(res => {
                if (res.data.code == '200') {
                    this.scheduleList = res.data.data
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 巡课方案选项改变
         * @Author: mrz
         * @Date: 2024-08-24 09:31:37
         */
         async scheduleChange(val){
            this.infoBool = false
            console.log('巡课方案改变上一次的值', this.firstProName,this.firstProId)
            if(this.basedFormData.targetId || this.basedFormData.classId || this.basedFormData.subjectId || this.basedFormData.classTime||this.value1){
                    this.$confirm('更改方案将会清空已填信息，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async ()=>{
                    this.showSubmit = false
                    // 清空校验
                    this.$refs.basedFormData.resetFields();
                    const obj = this.scheduleList.find(item => item.id === val)
                    this.basedFormData.programmeId = obj.id
                    this.basedFormData.programmeName = obj.name
                    this.basedFormData.programmeType = obj.type
                    this.startTime = obj.startTime
                    this.endTime = obj.endTime
                    // 清空已选择的选项
                    this.targetOptions = []
                    this.currentTarget = null
                    this.basedFormData.targetId = '',//巡课对象id
                    this.basedFormData.targetName = '',//巡课对象名称
                    this.basedFormData.classId = '',//巡课班级id
                    this.basedFormData.className ='',//巡课班级名称
                    this.basedFormData.classTeacherId ='',//上课教师id
                    this.basedFormData.classTeacherName ='',//上课教师名称
                    this.basedFormData.classTime ='',//上课时间
                    this.basedFormData.subjectId = '',//巡课科目id
                    this.basedFormData.subjectName ='',//巡课科目名称
                    this.basedFormData.gradeId = '',//年级id
                    this.basedFormData.gradeName = '',//年级名称
                    this.value1 = ''
                    this.value2 = ''
                    this.gradeObj.list = []
                    this.gradeObj.value = []
                    await this.getTargetByProId()
                }).catch(()=>{
                    this.basedFormData.programmeId = this.firstProName
                    this.basedFormData.programmeName = this.firstProId
                })
            }else{
                this.showSubmit = false
                // 清空校验
                this.$refs.basedFormData.resetFields();
                const obj = this.scheduleList.find(item => item.id === val)
                console.log('被选中的巡课方案:',obj)
                this.basedFormData.programmeId = obj.id
                this.basedFormData.programmeName = obj.name
                this.basedFormData.programmeType = obj.type
                this.startTime = obj.startTime
                this.endTime = obj.endTime
                // 清空已选择的选项
                this.targetOptions = []
                this.currentTarget = null
                this.basedFormData.targetId = '',//巡课对象id
                this.basedFormData.targetName = '',//巡课对象名称
                this.basedFormData.classId = '',//巡课班级id
                this.basedFormData.className ='',//巡课班级名称
                this.basedFormData.classTeacherId ='',//上课教师id
                this.basedFormData.classTeacherName ='',//上课教师名称
                this.basedFormData.classTime ='',//上课时间
                this.basedFormData.subjectId = '',//巡课科目id
                this.basedFormData.subjectName ='',//巡课科目名称
                this.basedFormData.gradeId = '',//年级id
                this.basedFormData.gradeName = '',//年级名称
                this.value1 = ''
                this.value2 = ''
                this.gradeObj.list = []
                this.gradeObj.value = []
                await this.getTargetByProId()
                this.firstProName = obj.name
                this.firstProId = obj.id
            }
         },
         /**
          * @Description: 上课时间起止时间限制
          * @Author: mrz
          * @Date: 2024-08-24 11:11:11
          * @param {*} time
          */
         disabledDate(time) {
            const startDate = this.startTime; // 起始日期
            const endDate = this.endTime; // 结束日期
            const start = new Date(startDate).getTime();
            const end = new Date(endDate).getTime();
            return time.getTime() < start || time.getTime() > end;
        },
        /**
         * @Description: 获取科目列表
         * @Author: mrz
         * @Date: 2024-08-24 10:20:33
         */
        async getSubjectList(){
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.getSchoolSubjectsList({'schoolId': this.schoolId, 'dictKey':"teachingSubjects"}).then((res) => {
                if (res.data.code == '200') {
                    console.log('科目列表：',res.data.data)
                    this.subjectList = res.data.data
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 科目选项改变
         * @Author: mrz
         * @Date: 2024-08-24 10:28:33
         */
         subjectChange(val){
            const obj = this.subjectList.find(item => item.id === val)
            this.basedFormData.subjectId = obj.id
            this.basedFormData.subjectName = obj.name
            console.log('this.basedFormData科目改变',this.basedFormData)
         },
        /**
         * @Description: 根据方案id查询巡课对象--固定巡课
         * @Author: mrz
         * @Date: 2024-08-24 16:34:08
         */
        async getTargetByProId(){
            let tBool = false
            let sBool = false
            this.keyArrTeacher = []
            this.keyArrStu = []
            this.teacherOptions = []
            const classscheduleModel = new classScheduleModel();
            // 根据方案id查询巡课对象
            await classscheduleModel.getTargetByProId({id:this.basedFormData.programmeId}).then(res => {
                //console.log('根据方案id查询巡课对象:',res)
                if(res.data.code == 200){
                    if(this.basedFormData.programmeType == '2'){
                        this.currentProFixedObj = res.data.data
                        // 固定巡课--处理数据
                        // this.handleGrade(res.data.data)
                        // this.handleTarget(res.data.data)
                    }else if(this.basedFormData.programmeType == '1'){
                        // 自由巡课
                        res.data.data .forEach(t => {  //自由巡课巡课对象为教师-教师列表
                            this.teacherOptions.push({
                                value:t.id,
                                label:t.name
                            })
                        })
                    }

                }else if(res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            // 根据方案id查询方案详情--固定巡课/自由巡课
            await classscheduleModel.getPatrolDetailsById({id:this.basedFormData.programmeId}).then(res => {
                if(res.data.code == 200){
                    let arr = []
                    this.assessType = res.data.data.assessType
                    res.data.data.target.forEach(item => {
                        arr.push(item.id)
                    })
                   if(this.basedFormData.programmeType == '1'){
                        // 自由巡课
                        this.targetType = res.data.data.targetType //巡课对象1班级2教师
                        if(this.targetType == '1'){
                            // 获取班级列表
                            this.getTargetClassList(arr)
                        }else{
                            // 巡课对象为教师时获取班级列表
                            this.getGradeClassListData()
                        }
                    }
                    // 详情数据处理---教师信息
                    this.contentList = res.data.data.map
                    const obj = {}
                    const rulesTeacher = {}
                    // console.log('this.contentList------',this.contentList)
                    if(this.contentList.hasOwnProperty('1')){
                        for(let key in this.contentList[1]){
                            if(this.contentList[1].hasOwnProperty(key)){
                                //console.log('教师排序：',this.contentList[1][key][0]['conSort'])
                                let a = this.contentList[1][key][0]['conSort'] - 1 //顺序字段
                                this.keyArrTeacher[a] = key
                            }
                        }
                    }else{
                        this.keyArrTeacher = []
                    }

                    //console.log('排序后的keyArrTeacher---',this.keyArrTeacher)
                    // this.keyArrTeacher = this.contentList.hasOwnProperty('1') ? Object.keys(this.contentList[1]) : []
                    this.$nextTick(async ()=>{
                        await this.keyArrTeacher.forEach(item => {
                            //console.log('this.contentList[1][item]',item,this.contentList[1][item])
                            let type = this.contentList[1][item][0]['type'];
                            obj[item] = {
                                contentId:item,
                                contentSetId:'',
                                contentSetScore:undefined,
                                contentSetInfo:'',
                            };
                            // 动态表单校验规则
                            rulesTeacher[item] = {
                                contentSetId:[{
                                    required: true, message: '请选择选项', trigger: 'blur'
                                }],
                                contentSetIdBack:[{
                                    required: true, message: '请选择选项', trigger: 'blur'
                                }],
                                contentSetScore:[{
                                    required: true, message: '请填写选项', trigger: 'blur'
                                }],
                                contentSetInfo:[{
                                    required: true, message: '请填写评价', trigger: 'blur'
                                }]
                            }
                            obj[item]['contentSetIdBack'] = type == '2' ? [] : '';
                            obj[item]['contentSetId'] = type == '3' ? this.contentList[1][item][0]['setId'] : '';
                        });

                        this.rulesTeacher = JSON.parse(JSON.stringify(rulesTeacher))
                        this.infoTeacher = JSON.parse(JSON.stringify(obj))
                        tBool = true
                        // console.log('tBool',tBool)
                    })

                    // console.log('根据方案id查询巡课详情infoTeacher:', this.infoTeacher,"根据方案id查询巡课详情-教师contentList",this.contentList[1])
                    // 学生信息
                    const objStu = {}
                    const rulesStudent = {}
                    // 排序
                    if(this.contentList.hasOwnProperty('2')){
                        for(let key in this.contentList[2]){
                            if(this.contentList[2].hasOwnProperty(key)){
                                let a = this.contentList[2][key][0]['conSort'] - 1 //顺序字段
                                this.keyArrStu[a] = key
                            }
                        }
                    }else{
                         this.keyArrStu = []
                    }
                    // console.log('排序后的keyArrStu---',this.keyArrStu)
                    // this.keyArrStu =  this.contentList.hasOwnProperty('2') ? Object.keys(this.contentList[2]) : []
                    this.$nextTick(async ()=>{
                        await this.keyArrStu.forEach(item => {
                            let type = this.contentList[2][item][0]['type'];
                            objStu[item] = {
                                contentId:item,
                                contentSetId:'',
                                contentSetScore:undefined,
                                contentSetInfo:''
                            };
                            rulesStudent[item] = {
                                contentSetId:[{
                                    required: true, message: '请选择选项', trigger: 'blur'
                                }],
                                contentSetIdBack:[{
                                    required: true, message: '请选择选项', trigger: 'blur'
                                }],
                                contentSetScore:[{
                                    required: true, message: '请填写选项', trigger: 'blur'
                                }],
                                contentSetInfo:[{
                                    required: true, message: '请填写评价', trigger: 'blur'
                                }]
                            }
                            objStu[item]['contentSetIdBack'] = type == '2' ? [] : '';
                            objStu[item]['contentSetId'] = type == '3' ? this.contentList[2][item][0]['setId'] : '';
                        })
                        this.infoStudent = JSON.parse(JSON.stringify(objStu))
                        this.rulesStudent = JSON.parse(JSON.stringify(rulesStudent))

                        this.infoRemark = {
                            contentId:"beizhushuoming",
                            contentSetId:'beizhushuoming',
                            contentSetScore:undefined,
                            contentSetInfo:'',
                        }
                        this.setNewCorData()
                        sBool = true

                        if(tBool && sBool){
                            this.infoBool = true
                        }
                        console.log('infoBool',this.infoBool)
                    })
                    // console.log('根据方案id查询巡课详情infoStudent:', this.infoStudent,"根据方案id查询巡课详情-学生contentList",this.contentList[2])
                }else if(res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },
        setNewCorData() {
            let _this = this;
            this.CorData = {};
            for (let key of  this.keyArrTeacher) {
                let rulesList = {};
                this.CorData[key] = {
                    values: _this.infoTeacher[key],
                };
                for (let keys in _this.rulesTeacher[key]) {
                    _this.rulesTeacher[key][keys].forEach(element => {
                        if (element.hasOwnProperty('required')) {
                            rulesList[keys] = {
                                type: true,
                                msg: element.message
                            };
                        }
                    });
                }
                this.CorData[key]['rules'] = rulesList;
            }
            // 学生
            this.CorDataStu = {}
            for (let key of  this.keyArrStu) {
                let rulesList = {};
                this.CorDataStu[key] = {
                    values: _this.infoStudent[key],
                };
                for (let keys in _this.rulesStudent[key]) {
                    _this.rulesStudent[key][keys].forEach(element => {
                        if (element.hasOwnProperty('required')) {
                            rulesList[keys] = {
                                type: true,
                                msg: element.message
                            };
                        }
                    });
                }
                this.CorDataStu[key]['rules'] = rulesList;
            }
            // console.log('this.CorDataStu',this.CorDataStu)
            // console.log('this.CorData',this.CorData)
        },
        /**
         * @Description: 自由巡课巡课对象为教师时获取的班级列表
         * @Author: mrz
         * @Date: 2024-08-30 16:01:17
         */
        async getGradeClassListData(){
            await this._fet("/school/schoolOrgan/getAllGradeClassList", {
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                console.log('获取的班级列表：',res)
                if (res.data.code === "200") {
                    this.gradeObj.list = formatTreeData(res.data.data, "id", "parentOrg")
                    console.log('班级下拉列表',this.gradeObj.list)
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 自由巡课对象为班级获取班级列表/固定巡课时获取弹框内班级下拉列表
         * @Author: mrz
         * @Date: 2024-08-27 08:56:20
         */
         async getTargetClassList(arr){
            console.log("ids:",arr)
            const classscheduleModel = new classScheduleModel();
            await classscheduleModel.getOrganTreeByClassIds({ids:arr}).then(res => {
                this.gradeList = res.data.data.list
                if(this.gradeList.length>0){
                    this.gradeObj.list = formatTreeData(res.data.data.list, "id", "parentOrg")
                    console.log('班级下拉列表',this.gradeObj.list)
                }
            })
         },
        /**
         * @Description: 固定巡课--处理巡课对象弹框班级下拉框数据
         * @Author: mrz
         * @Date: 2024-08-26 10:59:46
         */
         async handleGrade(list){
            console.log('固定巡课--处理巡课对象弹框班级下拉框数据',list)
            let classArr = []
            if(list.length>0){
                list.forEach(item => classArr.push(item.scheduleClassId))
                let arr = Array.from(new Set(classArr))
                await this.getTargetClassList(arr)
            }
         },
        /**
         * @Description: 固定巡课--处理巡课对象成需要的格式--根据日期星期分组
         * @Author: mrz
         * @Date: 2024-08-26 09:54:33
         */
         async handleTarget(list){
            if(list.length>0){
                list.sort((a,b) => new Date(a.scheduleTime) - new Date(b.scheduleTime))
                this.list = []
                const target = {}
                list.forEach((item,index) => {
                    let {scheduleTime} = item
                    if(!target[scheduleTime]){
                        target[scheduleTime] = {
                            scheduleTime,
                            child:[]
                        }
                    }
                    target[scheduleTime].child.push(item)
                })
                this.list = Object.values(target)
                if(this.list.length>0){
                    await this.list.forEach(item => {
                        if(item.child.length>0){
                            item.child.sort((a,b) => (a.restTimeStartTime.split(":")[0]*3600 + a.restTimeStartTime.split(":")[1]*60) -( b.restTimeStartTime.split(":")[0]*3600 + b.restTimeStartTime.split(":")[1]*60))
                        }
                    })
                }
                console.log('巡课对象处理后的数据：',this.list)
            }else{
                this.list = []
            }
         },
        /**
         * @Description: 固定巡课点击巡课对象显示巡课对象弹框
         * @Author: mrz
         * @Date: 2024-08-25 09:01:36
         */
         async fixedDialog(){
            // 清空弹框内筛选框
            this.targetObj={
                class:'',
                teacher:''
            }
            this.gradeObj.list = []
            this.gradeObj.value = []
            // 当前方案下巡课对象处理格式及班级下拉框数据
            await this.handleGrade(this.currentProFixedObj)
            await this.handleTarget(this.currentProFixedObj)
            // 若当前有被选中的样式修改
            console.log('this.currentTarget',this.currentTarget)
            if(this.currentTarget){
                let id = this.currentTarget.id
                this.list.forEach(item => {
                    item.child.forEach(subitem => {
                        const el = document.getElementById(`detail_${subitem.id}`)
                        if (subitem.id == id) {
                            this.$set(subitem, 'sel', true)
                            el.style.border = '1px solid #3C7FFF'
                        } else {
                            this.$set(subitem, 'sel', false)
                            el.style.backgroundColor = '#F4F4F5'
                        }
                    })
                })
            }
            this.patrolTargetDialogObj.dialogVisible = true
         },
        /**
         * @Description: 关闭巡课对象弹框
         * @Author: mrz
         * @Date: 2024-08-25 09:08:58
         */
        handlepatrolTargetDialogClose(){
            this.patrolTargetDialogObj.dialogVisible = false
            this.list.forEach(item => {
                item.child.forEach(subitem => {
                    const el = document.getElementById(`detail_${subitem.id}`)
                    this.$set(subitem, 'sel', false)
                    el.style.border = "1px solid #E9E9EB"
                    el.style.backgroundColor = '#F4F4F5'
                })
            })
        },
        /**
         * @Description: 选中巡课对象
         * @Author: mrz
         * @Date: 2024-08-25 14:09:07
         */
        selectTarget(val){
            this.currentTargetTemporary = val
            let id = val.id
            this.list.forEach(item => {
                item.child.forEach(subitem => {
                    const el = document.getElementById(`detail_${subitem.id}`)
                    if (subitem.id == id) {
                        this.$set(subitem, 'sel', true)
                        el.style.border = '1px solid #3C7FFF'
                    } else {
                        this.$set(subitem, 'sel', false)
                        el.style.border = '1px solid #F4F4F5'
                        el.style.backgroundColor = '#F4F4F5'
                    }
                })
            })
        },
        /**
         * @Description: 固定巡课巡课对象弹框查询确定
         * @Author: mrz
         * @Date: 2024-08-26 11:33:35
         */
        getTargetObj:debounce(function (){
            console.log('巡课对象弹框查询条件：',this.targetObj)
            const classscheduleModel = new classScheduleModel();
            const obj = {
                id:this.basedFormData.programmeId,
                classId:this.targetObj.class,
                teacherName:this.targetObj.teacher
            }
            // 根据方案id查询巡课对象
            classscheduleModel.getTargetByProId(obj).then(res => {
                if(res.data.code == 200){
                   // 固定巡课--处理数据
                   this.handleTarget(res.data.data)
                   if(this.currentTarget){
                        let id = this.currentTarget.id
                        this.$nextTick(()=>{
                            this.list.forEach(item => {
                                item.child.forEach(subitem => {
                                    const el = document.getElementById(`detail_${subitem.id}`)
                                    if (subitem.id == id) {
                                        this.$set(subitem, 'sel', true)
                                        el.style.border = '1px solid #3C7FFF'
                                        el.style.backgroundColor = '#DFEAFF'
                                    } else {
                                        this.$set(subitem, 'sel', false)
                                        el.style.border = '1px solid #E9E9EB'
                                        el.style.backgroundColor = '#F4F4F5'
                                    }
                                })
                            })
                        })
                    }
                }else if(res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },250),
        /**
         * @Description: 下拉框数据处理
         * @Author: mrz
         * @Date: 2024-08-26 11:43:01
         */
        handleOptionChange(value){
            this.targetObj.class = value[1]
            console.log('value--',value)
        },
        /**
         * @Description: 固定巡课--巡课对象点击确定
         * @Author: mrz
         * @Date: 2024-08-26 11:52:30
         */
        targetDialogClick(){
            this.currentTarget = this.currentTargetTemporary
            if(!this.currentTarget){
                this.$message.error("请先选择巡课对象")
                return
            }
            console.log("固定巡课对象弹框--",this.currentTarget)
            const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const date = new Date(this.currentTarget.scheduleTime)
            const weekday = days[date.getDay()]

            this.patrolTargetDialogObj.dialogVisible = false
            this.basedFormData.className = this.currentTarget.scheduleGradeName + '/' + this.currentTarget.className
            this.basedFormData.classId = this.currentTarget.scheduleClassId
            this.basedFormData.gradeName = this.currentTarget.scheduleGradeName
            this.basedFormData.gradeId = this.currentTarget.scheduleGradeId
            this.basedFormData.classTeacherId = this.currentTarget.classTeacherId
            this.basedFormData.classTeacherName = this.currentTarget.teacherName
            this.basedFormData.classTime = this.currentTarget.scheduleTime + this.currentTarget.restTimeName.substring(0,3) + this.currentTarget.restTimeStartTime.substring(0,5) + '--' + this.currentTarget.restTimeEndTime.substring(0,5)
            // 拼装数据
            this.basedFormData.targetId = this.currentTarget.id
            this.basedFormData.targetName = this.currentTarget.teacherName + '(' + this.currentTarget.scheduleGradeName + this.currentTarget.className + ') '  + weekday + '('+this.currentTarget.scheduleTime.replace(/-/g,'/') + ')'
            this.basedFormData.subjectName = this.currentTarget.subjectName
            this.basedFormData.subjectId = this.currentTarget.subjectId
        },
        handlerTeacherChange () {
            this.$emit('handlerTeacherChange')
        },
    }
}
</script>

<style lang="scss" scoped>
.based-content{
    width: 100%;
    height: calc(100vh - 171px);
    overflow-y: auto;
    .based-title{
        box-sizing: border-box;
        padding: 16px 0 12px 19px;
        border-bottom: 1px solid #EDEFF2;
        position: relative;
        .based-line{
            position: absolute;
            left: 23px;
            bottom: -1px;
            width: 56px;
            height: 3px;
            background: #3C7FFF;
            border-radius: 2px;
        }
        span{
            display: flex;
            justify-content: flex-start;
            height: 16px;
            line-height: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #2B2F33;
        }
    }
    .based-main{
        padding: 6px 10px;
        .based-form{
            box-sizing: border-box;
            height: 94px;
            background: #F5F9FD;
            border-radius: 4px;
            padding:10px
        }
        ::v-deep .el-form-item__label{
            width: 57px;
            height: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 32px;
            padding: 0;
        }
        ::v-deep .el-select{
            width: 260px;
        }
        ::v-deep .el-input{
            width: 260px;
        }
        ::v-deep .el-radio{
            text-align: left;
            width: 260px;
            margin-right: 0;
        }
        ::v-deep .el-range-editor--small.el-input__inner{
            width: 260px;
        }
        ::v-deep .el-row{
            height: 42px;
        }
    }
    .detail-main{
        min-height: calc(100vh - 431px);
        overflow-y: auto;
        .empty-div{
            height: calc(100vh - 431px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .empty-info{
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
            }
        }
        .behavior-div{
            padding:0 22px;
            box-sizing: border-box;
            .behavior-line{
                height: 0;
                width: 100%;
                border-bottom: 1px dashed #E1E3E6;
            }
            .behavior-title{
                height: 48px;
                padding: 16px 0;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .title-line{
                    display: inline-block;
                    width: 4px;
                    height: 15px;
                    background: #3C7FFF;
                    border-radius: 1px;
                    margin-right: 7px;
                }
                .title-info{
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    font-size: 16px;
                    color: #2B2F33;
                }
            }
            .behavior-content{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 30px;
                .behavior-content-title{
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 5px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #2B2F33;
                }
                .radioDiv{
                    margin: 10px 38px 10px 0;
                    text-align: left;
                    min-width: 444px;
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    ::v-deep .el-checkbox__label{
                        white-space:nowrap;
                        overflow:hidden;
                        text-overflow:ellipsis;
                    }
                }
            }
        }
    }
}
.patrol-target-search{
    ::v-deep .el-form-item--small.el-form-item{
        margin-bottom: 13px !important;
    }
    ::v-deep .el-input--small .el-input__inner{
        background-color: #fff;
    }
    ::v-deep .collapseCascader {
        width: 160px;
    }
    .patrol-target-line{
        width: 100%;
        height: 0;
        border-bottom: 1px dashed #D8D8D8;
    }
}
.remark{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .remark-info{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-left: 16px;
    }
}
.patrol-target-timetable{
    margin-bottom: 23px;
}
.common-form .el-input__inner{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.target-img{
    height:454px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .target-info{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
    }
}
::v-deep .el-dialog__body{
    margin: 0 !important;
    padding: 17px 40px 0px 31px !important;
}
::v-deep .el-cascader__tags{
    left: 6px;
}
::v-deep .el-cascader-menu{
    min-width: 140px;
}
::v-deep .el-input__prefix{
    display: none;
}
::v-deep .el-date-editor .el-range__icon{
    display: none;
}
::v-deep .el-date-editor .el-range-input{
    width: 100%;
}
::v-deep .el-range-editor--small .el-range-input{
    font-size: 12px;
}
::v-deep .el-input--prefix .el-input__inner{
    padding-left: 24px;
}
::v-deep .el-form-item__error {
    display: none;
}
.time-title{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin-right: 19px;
    transform: translateY(6px);
}
.student-radio-div{
    margin-left: 23px;
    display:flex;
    flex-wrap: wrap
}
.student-radio-div ::v-deep .el-checkbox{
    display: flex;
    align-items: center;
    width: 444px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.propt{
    font-size: 12px;
    margin-left: 23px;
    color:red;
    display:flex;
    justify-content: flex-start;
}
::v-deep .el-date-editor .el-range__close-icon{
    display: none;
}
::v-deep .el-range-editor.el-input__inner{
    padding: 3px;
}
::v-deep .el-dialog{
    height: 615px;
}
</style>
